import { useCallback } from 'react';
import { Link } from 'routes';
import Image from 'next/image';

import { logEvent } from 'utils/analytics';
import { trackingEventForGA4 } from 'utils/eventTrackingForGA4';
import styles from './ProCTALink.module.scss';

interface Props {
  eventLabel?: 'c1' | 'c2' | 'c3' | 'vert' | '';
  eventType: 'verticalProCTAClicked' | 'blogProCTAClicked' | 'topPageProCTAClicked';
  dataGtmClick?: string;
}

const ProCTALink = ({ eventType, eventLabel = '', dataGtmClick = 'gtm-proCTA' }: Props) => {
  const handleLinkClick = useCallback(() => {
    // NOTE: Delete logEvent when migrate to GA4
    logEvent('proSignUp', `${eventType}`, `${eventLabel}`);
    trackingEventForGA4(`${eventType}`, 'proSignUp', `${eventLabel}`);
  }, [eventType, eventLabel]);

  return (
    <div className={styles['top-page-link']}>
      <Link prefetch={false} route="/pro">
        <a role="link" onClick={handleLinkClick} tabIndex={0} data-gtm-click={dataGtmClick}>
          <Image
            alt=""
            unoptimized={true}
            src="/static/images/pro-cta-link.png"
            width={672}
            height={132}
          />
        </a>
      </Link>
    </div>
  );
};

export default ProCTALink;
