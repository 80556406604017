import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Link from 'next/link';

import Journal from '../../atoms/Journal';
import SeeMoreIconButton from '@z-components/atoms/SeeMoreIconButton';

import styles from './JournalGroup.module.scss';

const EXCLUDED_TITLE_WORD = '目次';

export interface BlogPost {
  /* eslint-disable camelcase */
  _embedded: {
    'wp:featuredmedia'?: Array<{ source_url: string }>;
  };
  content?: {
    rendered?: string;
  };
  createdDate: number;
  excerpt: {
    rendered: string;
  };
  internalPath: string;
  name: string;
  title?: { rendered?: string };
  updatedDate: number;
  /* eslint-enable camelcase */
}

export interface BlogPostContentParsed {
  props?: {
    children?:
      | string
      | {
          props?: {
            children?: string;
          };
        };
  };
  type: string;
}

interface Props {
  posts?: Array<BlogPost>;
}

const JournalGroup: FC<Props> = ({ posts = [] }) => {
  const [postsShown, setPostsShown] = useState(2);
  const { t } = useTranslation();

  const onSeeMore = useCallback(() => {
    setPostsShown((numberOfPostsShown) => numberOfPostsShown + 2);
  }, []);

  const getAltTag = (post: BlogPost) => {
    if (!post.content) {
      return '';
    }
    const str = post.content.rendered;
    const doc = parse(str ?? '');
    let firstH2:
      | {
          props?: {
            children?:
              | string
              | {
                  props?: {
                    children?: string;
                  };
                };
          };
        }
      | undefined;
    if (Array.isArray(doc)) {
      firstH2 = doc.find((ele: BlogPostContentParsed) => {
        if (ele.type === 'h2' && typeof ele.props?.children === 'string') {
          return !ele.props.children.includes(EXCLUDED_TITLE_WORD);
        }
        return false;
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!firstH2) {
      return post.title?.rendered ?? '';
    }

    if (firstH2.props && typeof firstH2.props.children === 'string') {
      return firstH2.props.children;
    }

    return '';
  };

  return (
    <div className={styles['container']} data-test-id="journals-section">
      <div className={styles['journals-container']} data-cy={'number-of-blog'}>
        {posts.slice(0, postsShown).map((post) => {
          const link = post.internalPath;
          // eslint-disable-next-line no-underscore-dangle
          const featuredMedia = post._embedded['wp:featuredmedia'] ?? [];
          return (
            <Journal
              altTag={getAltTag(post)}
              key={link}
              createdDate={post.createdDate}
              // eslint-disable-next-line no-underscore-dangle
              imgUrl={featuredMedia.length > 0 ? featuredMedia[0].source_url : undefined}
              linkUrl={link}
              linkText={post.title?.rendered}
              description={post.excerpt.rendered}
              updatedDate={post.updatedDate}
            />
          );
        })}
      </div>
      {postsShown < posts.length && (
        <div className={styles['see-more-container']}>
          <SeeMoreIconButton onClick={onSeeMore} />
        </div>
      )}
      {posts.length > 0 && (
        <div className={styles['see-latest-articles-section']}>
          <Link href={'/blog'} prefetch={false} passHref={true}>
            <a>{t('common:topPage.seeMoreLatestArticles')}</a>
          </Link>
        </div>
      )}
    </div>
  );
};

export type { Props as JournalGroupProps };
export default JournalGroup;
