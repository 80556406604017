import { ButtonHTMLAttributes, FC } from 'react';
import classNames from 'classnames';
import ArrowDownIcon from '@z-components/static/images/icons/Icon_Down.svg';

import styles from './SeeMoreIconButton.module.scss';

const TRANSLATIONS = {
  en: {
    seeMore: 'See More',
  },
  ja: {
    seeMore: 'もっと見る',
  },
};
interface Props {
  className?: string;
  hasIcon?: boolean;
  language?: 'en' | 'ja';
  onClick?: ButtonHTMLAttributes<HTMLDivElement>['onClick'];
  testId?: string;
  text?: string;
}

export const SeeMoreIconButton: FC<Props> = ({
  language = 'ja',
  className = '',
  onClick = () => undefined,
  testId = '',
  text,
  hasIcon = true,
}) => {
  const btnText = text ?? TRANSLATIONS[language].seeMore;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames(styles['z-see-more-icon-button'], className)}
      onClick={onClick}
      data-testid={testId}
      data-cy={'see-more'}
    >
      {hasIcon && <ArrowDownIcon className={styles['z-see-more-icon-button-icon']} />}
      {btnText}
    </div>
  );
};

export default SeeMoreIconButton;
export type { Props as SeeMoreIconButtonProps };
