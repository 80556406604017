import { FC } from 'react';
import Head from 'next/head';

import {
  addTransformToCloudinaryUrl,
  convertExternalToCloudinary,
  isCloudinaryUrl as checkIsCloudinaryUrl,
} from 'utils/url';
import { useImageLoaded } from 'src/hooks';

const HEIGHT_360 = 360;
const WIDTH_1000 = 1000;
const WIDTH_600 = 600;
const WIDTH_360 = 360;

interface Props {
  alt?: string;
  bgImg: string;
  bgImgHeight?: string | number;
  bgImgObjectFit?: 'cover' | 'contain';
  bgImgPosition?: 'static' | 'absolute';
  bgImgWidth?: string | number;
  bgPos?: string;
  fullHeight?: boolean;
  fullHeightOption?: string;
  isResponsive?: boolean;
  jobTypeId?: string;
  onImageLoadError?: () => void;
  onLoadingComplete?: ({ width, height }: { height: number; width: number }) => void;
}

const Jumbotron: FC<Props> = ({
  alt = '',
  bgImg = '',
  bgPos = 'right',
  bgImgObjectFit = 'cover',
  bgImgPosition = 'static',
  bgImgHeight,
  bgImgWidth,
  onLoadingComplete,
  onImageLoadError,
  children = null,
  fullHeight = false,
  fullHeightOption = '',
  isResponsive = false,
}) => {
  const [ref] = useImageLoaded(onLoadingComplete);
  const isCloudinaryUrl = checkIsCloudinaryUrl(bgImg);
  const isStaticUrl = bgImg.includes('/static/images');
  const backgroundImageUrl =
    isCloudinaryUrl || isStaticUrl
      ? bgImg
      : convertExternalToCloudinary(bgImg, { shouldTransform: false });

  const imgUrl1000 = addTransformToCloudinaryUrl(backgroundImageUrl, {
    isCloudinaryFetchTransform: !isCloudinaryUrl,
    crop: 'fill',
    dpr: '1.0',
    // eslint-disable-next-line no-magic-numbers
    quality: 'auto',
    shouldUseProxy: true,
    width: WIDTH_1000,
  });
  const imgUrl600 = addTransformToCloudinaryUrl(backgroundImageUrl, {
    isCloudinaryFetchTransform: !isCloudinaryUrl,
    crop: 'fill',
    dpr: '1.0',
    // eslint-disable-next-line no-magic-numbers
    quality: 'auto',
    shouldUseProxy: true,
    width: WIDTH_600,
  });
  const imgUrl360 = addTransformToCloudinaryUrl(backgroundImageUrl, {
    isCloudinaryFetchTransform: !isCloudinaryUrl,
    crop: 'fill',
    dpr: '1.0',
    height: HEIGHT_360,
    // eslint-disable-next-line no-magic-numbers
    quality: 'auto',
    shouldUseProxy: true,
    width: WIDTH_360,
  });

  const hasExplicitHeight = fullHeight;
  const responsiveDimensions = !hasExplicitHeight && isResponsive && 'height: 100%;\nwidth:100%';

  /**
   * IMPORTANT NOTE
   * The bgImg should never be lazy loaded, because it is rendered on load in the client viewport and it does impact LCP
   * Google Lighthouse or even Page Speed Insight might give warning about the fact that this iamge is not lazy loaded, but IGNORE this warning
   * Because if we lazy loaded the jumbotron image, it will drastically increase LCP.
   *
   * Not all image should be lazy loaded.
   * Only images that initially appear outside the viewport can be lazy loaded.
   */

  let heightStyle = '';
  if (fullHeight) {
    heightStyle = 'height: 100vh';
    if (fullHeightOption !== '') {
      heightStyle = fullHeightOption;
    }
  }

  return (
    <section className="jumbotron" data-test-id="jumbotron">
      {bgImg && (
        <img
          ref={ref}
          src={imgUrl1000}
          srcSet={`${imgUrl360} 359w, ${imgUrl600} 599w, ${imgUrl1000} 999w`}
          alt={alt}
          onError={onImageLoadError}
          height={bgImgHeight}
          width={bgImgWidth}
        />
      )}
      {bgImg && (
        <Head>
          <link
            rel="preload"
            as="image"
            href={imgUrl1000}
            // @ts-expect-error not property type
            imagesrcset={`${imgUrl360} 359w, ${imgUrl600} 599w, ${imgUrl1000} 999w`}
          />
        </Head>
      )}
      {children}
      <style jsx>
        {`
          .jumbotron {
            padding: 0;
            position: relative;
            ${heightStyle}
            ${responsiveDimensions ? responsiveDimensions : ''}
          }
          .jumbotron > img {
            width: 100%;
            object-fit: ${bgImgObjectFit};
            object-position: ${bgPos};
            position: ${bgImgPosition};
            z-index: -1;
            height: 100%;
          }
        `}
      </style>
    </section>
  );
};

export type { Props as JumbotronProps };
export default Jumbotron;
