import { FC, MouseEventHandler } from 'react';
import classnames from 'classnames';

import IconRight from 'images/icons/Icon_Right.svg';
import styles from './RequestFormCTA.module.scss';

const PromotionalBadge: FC<{ classes?: string }> = ({ classes }) => (
  <div className={classnames(styles['promotional'], classes)}>
    <span>無料</span>
  </div>
);

const ConditionallyRenderedCTAText: FC = () => (
  <span className={styles['conditional-text']}>あなたの</span>
);

type RequestFormCTAProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  hasDropShadow?: boolean;
  gtmClasses?: string;
  dataGtmClick?: string;
};

const RequestFormCTA: FC<RequestFormCTAProps> = ({
  onClick,
  hasDropShadow,
  gtmClasses,
  dataGtmClick,
}) => (
  <button
    className={classnames(styles.button, gtmClasses, {
      [styles['button_drop-shadow']]: hasDropShadow,
    })}
    onClick={onClick}
    data-gtm-click={dataGtmClick}
  >
    <PromotionalBadge classes={styles['promotional-container']} />
    <div className={styles['text-container']}>
      <div className={styles['emphasize']}>
        カンタンな質問に答えて、
        <br />
        <span>
          <ConditionallyRenderedCTAText />
          近くのプロを探す
        </span>
      </div>
    </div>
    <div className={styles['icon-right']}>
      <IconRight height={'100%'} width={48} />
    </div>
  </button>
);

export type { RequestFormCTAProps };
export default RequestFormCTA;
