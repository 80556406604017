import { useEffect, useRef } from 'react';

type Callback = ({ width, height }: { height: number; width: number }) => void;
const useImageLoaded = (onImageLoaded?: Callback) => {
  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (ref.current?.complete && onImageLoaded) {
      onImageLoaded({ width: ref.current.naturalWidth, height: ref.current.naturalHeight });
    }
  });

  return [ref];
};

export default useImageLoaded;
