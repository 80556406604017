import { FC, useState } from 'react';
import dayjs from 'dayjs';
import LazyLoad from 'react-lazyload';
import Link from 'next/link';

import styles from './Journal.module.scss';
import { convertExternalToCloudinary } from 'utils/url';

import IconCreateDate from 'images/icons/Icon_Created_Date.svg';
import IconUpdatedDate from 'images/icons/Icon_Updated_Date.svg';

interface Props {
  altTag: string;
  createdDate: string | number;
  description?: string;
  imgUrl?: string;
  linkText?: string;
  linkUrl: string;
  updatedDate: string | number;
}

const WIDTH = 400;

const Journal: FC<Props> = ({
  altTag = '',
  imgUrl = '',
  linkText,
  linkUrl,
  description = '',
  createdDate,
  updatedDate,
}) => {
  const formattedCreatedDate = dayjs(createdDate).format('YYYY/MM/DD');
  const formattedUpdatedDate = dayjs(updatedDate).format('YYYY/MM/DD');
  let displayableUrl;
  if (imgUrl.includes('localhost')) {
    // useful when the post and featuredImage came from a local env
    displayableUrl = imgUrl;
  } else {
    displayableUrl = convertExternalToCloudinary(imgUrl, { width: WIDTH });
  }

  const isExternalLink = !linkUrl.startsWith('/');
  const [imgSrc, setImgSrc] = useState(displayableUrl);

  return (
    <div className={styles['container']}>
      {imgSrc && (
        <LazyLoad once={true}>
          <a href={linkUrl} className={'d-block'}>
            <img
              alt={altTag}
              src={imgSrc}
              onError={() => {
                setImgSrc('');
              }}
            />
          </a>
        </LazyLoad>
      )}

      <div className={styles['date']}>
        {formattedCreatedDate === formattedUpdatedDate && (
          <>
            <IconCreateDate height={12} width={12} /> <span>{formattedCreatedDate}</span>
          </>
        )}
        {formattedCreatedDate !== formattedUpdatedDate && (
          <>
            <IconUpdatedDate height={12} width={12} /> <span>{formattedUpdatedDate}</span>
          </>
        )}
      </div>
      <Link href={linkUrl} passHref={isExternalLink}>
        <a className={styles['link']} data-cy={'latest-blog'}>
          {linkText}
        </a>
      </Link>
      <div className={styles['description']} dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default Journal;
